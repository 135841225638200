
import { Product } from '@/store/models/Product';
import { mixins } from 'vue-class-component';
import { Component, Prop } from 'vue-property-decorator';
import AppName from '../mixin/AppName.vue';

@Component
export default class CustomerViewHeader extends mixins(AppName) {
  @Prop() siteProduct!: Product;
  @Prop() isTable!: boolean;
}
