
import DraggablePanel from '@/components/buttons/DraggablePanel.vue';
import CustomerViewHeader from '@/components/common/CustomerViewHeader.vue';
import GoogleMap from '@/components/GoogleMap.vue';
import AppName from '@/components/mixin/AppName.vue';
import ConstantsMixin from '@/components/mixin/Constants.vue';
import SideContent from '@/components/sidebar/SideContent.vue';
import Datatable from '@/components/table/Datatable.vue';
import {
  FetchPendingSites,
  FetchSingularProduct
} from '@/services/api/ProductApi';
import { trackEvent } from '@/services/Mixpanel';
import { setHasNonFonterra, setMaxVatVolume } from '@/store/Globals';
import ColumnOrderMoveEvent from '@/store/models/ColumnOrderMoveEvent';
import { Product } from '@/store/models/Product';
import ProductStore from '@/store/modules/ProductStore';
import User from '@/store/modules/User';
import {
  adjustAndSetColumnOrder,
  orderColumnsBySavedOrder,
  removeSavedColumnOrder
} from '@/utils/ColumnOrdering';
import {
  getHasNonFonterra,
  getMaxCapacity
} from '@/utils/formatters/MilkEntitiesFormatter';
import {
  formatAppProducts,
  getAppMapTag,
  getVisibleAppTableColumnFormatters
} from '@/utils/GenericUtils';
import { getObjectItem } from '@/utils/LocalStorageUtils';
import { sortRequestedSites } from '@/utils/SortingUtils';
import { customerViewFields } from '@/utils/table-fields/TableFieldLists';
import { isAdmin } from '@/utils/TestingValidity';
import { mixins } from 'vue-class-component';
import { Component, Ref, Watch } from 'vue-property-decorator';
@Component({
  components: {
    GoogleMap,
    DraggablePanel,
    SideContent,
    Datatable,
    CustomerViewHeader
  },
  methods: {
    isAdmin
  }
})
export default class CustomerView extends mixins(AppName, ConstantsMixin) {
  @Ref('map') readonly googleMap!: GoogleMap;
  public isTable = true;
  public width = 1000;
  public siteProduct: Product | null = null;
  public loading = true;
  public selectedEntityIndex = 0;
  public mapHeight = 300;
  public tableColumns: any[] = [];

  created() {
    window.addEventListener('resize', () => {
      this.updateWidth();
    });
    this.$root.$on('orgIndexChanged', () => {
      this.getPendingSites();
    });
    this.$root.$on('customFieldsUpdated', () => {
      this.getPendingSites();
    });
    this.$root.$on('setEntityIndex', this.entitySelected);
    this.$root.$on(
      'columnOrderChanged',
      (move: ColumnOrderMoveEvent, columns: any[]) => {
        this.tableColumns = columns;
        adjustAndSetColumnOrder(move);
      }
    );
    this.loading = false;
  }

  mounted() {
    trackEvent('User viewing request page');
    ProductStore.updateCurrentEntityIndexState(0);
    this.loadTableColumns();
    this.getPendingSites();
    this.updateWidth();
  }

  destroyed() {
    window.removeEventListener('resize', () => {
      this.updateWidth();
    });
  }

  @Watch('siteProduct')
  siteUpdated() {
    this.$root.$emit('bounds_changed');
    this.addMarkerForProduct(this.siteProduct);
  }

  public loadTableColumns() {
    getVisibleAppTableColumnFormatters(customerViewFields, true).then(
      columns => {
        this.tableColumns = orderColumnsBySavedOrder(columns);
      }
    );
  }

  public updateWidth() {
    this.width = window.innerWidth;
    this.isTable = window.innerWidth > 1000;
    this.mapHeight = window.innerHeight / 4;
  }

  private getPendingSites() {
    this.loading = true;
    if (this.$route.params && +this.$route.params.productId) {
      FetchSingularProduct({
        partnerId: User._token?.orgs[User._orgIndex]?.orgId ?? 0,
        productId: +this.$route.params.productId
      }).then(entities => {
        setHasNonFonterra(getHasNonFonterra(entities));
        const groupedProducts = formatAppProducts(sortRequestedSites(entities));
        const filteredDown = groupedProducts.filter(product => {
          return product.productId == +this.$route.params.productId;
        });
        this.siteProduct = filteredDown ? filteredDown[0] : null;

        ProductStore.updateCurrentProductState(
          filteredDown ? filteredDown[0] : undefined
        );

        if (this.isApp(this.PRODUCT_TYPE_MILK)) {
          setMaxVatVolume(
            getMaxCapacity(this.siteProduct ? [this.siteProduct] : [])
          );
        }
        this.loading = false;
      });
    }
  }

  private addMarkerForProduct(product: Product | null): void {
    const mapSettings = getObjectItem(`${this.getAppName(false)}MapSettings`);
    let mapTagValue = '';
    if (mapSettings) {
      mapTagValue = mapSettings.mapTag;
    }
    if (this.googleMap && product) {
      this.googleMap.addMarker({
        lat: parseFloat(product.latitude),
        lon: parseFloat(product.longitude),
        tag: this.isApp(this.PRODUCT_TYPE_MILK)
          ? +product.supplierNumber
          : product.productId,
        markerVal: getAppMapTag(mapTagValue, product),
        supplyNum: product.supplierNumber,
        isSelected: true,
        mapTagValue: mapTagValue
      });
    }
  }

  public rowSelected(selectedProduct: Product, index: number) {
    this.entitySelected(index);
  }

  public entitySelected(entityIndex: number) {
    ProductStore.updateCurrentEntityIndexState(
      entityIndex == -1 ? 0 : entityIndex
    );
    this.selectedEntityIndex = entityIndex == -1 ? 0 : entityIndex;
  }

  public resetColumnOrder(): void {
    removeSavedColumnOrder();
    this.loadTableColumns();
  }
}
